.sidebar{
    padding: 1.5rem;
    width: 250px;
    float: left;
    min-height: 100vh;
    position: fixed;
    background-color: lightgray;;
}

.nav{
    background-color: black;
    min-height: 8vh;
}

.contentHolder{
    position: relative;
    left: 200px;
}

@media (max-width: 766px) {
    .sidebar{
        width: 0;
        padding: 0;
        overflow: hidden;
    }

    .contentHolder{
        left: 0;
    }
}